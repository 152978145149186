import { render, staticRenderFns } from "./Brusque.vue?vue&type=template&id=11326d29&scoped=true&"
import script from "./Brusque.vue?vue&type=script&lang=js&"
export * from "./Brusque.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11326d29",
  null
  
)

export default component.exports